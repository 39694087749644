<template>
  <div class="home page">
    <!-- HERO START -->
    <div class="ileti_hero">
      <div class="hero_container">
        <div class="jumboall">
          <div class="jumbotron rellax" v-rellax="{ speed: -1.0 }">
            <div class="headline rellax" v-rellax="{ speed: -0.6 }">
              <h1>
                <span class="blue">İleti Yönetim Sistemi</span> <br />
                Vatandaşlarımızın hizmetinde!
              </h1>
            </div>

            <div class="jumbotext normal rellax" v-rellax="{ speed: -1.0 }">
              <!-- <div class="jumboheader">Başvuru</div> -->
              <p class="jumbodesc">
                Ticari elektronik ileti izinlerinizi tek noktadan kolayca
                yönetebilir, dilediğiniz hizmet sağlayıcı ve marka için onay
                verebilir, ileti almak istemediğinizde ret hakkınızı
                kullanabilirsiniz.
              </p>
            </div>

            <!-- <div class="hero_btn_group normal rellax" v-rellax="{ speed: -1.4 }">
              <div class="btn">
                <router-link to="/hizmet-saglayici/basvuru/nasil-yapilir">Hemen Başvur</router-link>
              </div>
              <div class="devam btn">
                <router-link to="/hizmet-saglayici/basvuru/devam-giris">Mevcut Başvuruya Devam Et</router-link>
              </div>
            </div> -->
          </div>

          <div class="jumboimage rellax" v-rellax="{ speed: -1.8 }">
            <!-- <MainImage :imageCount="3" /> -->

            <!-- <div class="videoplay" >
                    <a href="https://youtu.be/kE-wPfDzQLo" target="_blank">
                     <div>
                         <img class="playthumb" src="@/assets/img/video_thumb_01.png" alt />
                         <img class="playbtn" src="@/assets/img/video_play.svg" alt />
                     </div>
                     </a>
                   </div> -->

            <div
              class="videoplay"
              @click="
                SET_MODAL('ModalVideoVatandas');
                SET_NOTIFY_REQUESTER_TYPE(0);
              "
            >
              <div>
                <img
                  class="playthumb"
                  src="@/assets/img/video_thumb_01.png"
                  alt
                />
                <img class="playbtn" src="@/assets/img/video_play.svg" alt />
              </div>
            </div>
          </div>
        </div>

        <div class="boxes rellax" v-rellax="{ speed: -0.8 }">
          <!-- <div class="box3">
            <img src="@/assets/img/icons/box-handshake.svg" alt />
            <div>
              <h3>Aracı Hizmet Sağlayıcılar</h3>
              <p>
                AHS API erişimi için lütfen
                <router-link to="/hizmet-saglayici/araci-hizmet-saglayicilar"
                  >tıklayınız</router-link
                >.
              </p>
            </div>
          </div> -->

          <div class="box3">
            <img src="@/assets/img/icons/box-handshake.svg" alt />
            <div>
              <h3>İleti ViA</h3>
              <p>
                Hizmet sağlayıcılarımızın vatandaşlardan güvenli bir şekilde ETK ve/veya KVK onay ve ret toplayabileceği VİA hizmetinin detayları için
                <a href="https://via.iletiys.com.tr/"
                  >tıklayınız</a
                >.
              </p>
            </div>
          </div>

          <div class="box1">
            <img src="@/assets/img/icons/box-sign.svg" alt />
            <div class="inner">
              <h3>Hizmet Sağlayıcı Başvuru</h3>
              <p>
                İleti Yönetim Sistemi'ne e-Devlet hesabınız veya elektronik
                imzanızla hızlıca kaydolabilirsiniz. İYS'ye kaydolmak veya daha
                önce başladığınız bir başvuruya devam etmek için
                <router-link to="/hizmet-saglayici/basvuru/nasil-yapilir"
                  >tıklayınız</router-link
                >.
              </p>

              <!-- MODAL AÇTIRAN <a class="blue" @click="SET_MODAL('ModalTaahhutname')" >buradan</a> -->
              <!-- <div @click="SET_MODAL('ModalTaahhutname')" class="btn gerekli"
            >
              <a><img src="@/assets/img/ico_info.svg" alt /> Gerekli belgeler</a>
              </div>-->
            </div>
          </div>

          <div class="box2">
            <img src="@/assets/img/icons/box-box.svg" alt />

            <div>
              <h3>İYS Hizmetleri ve Fiyatları</h3>
              <p>
                Temel hizmetlerimize ek olarak alabileceğiniz kurumsal hizmet
                paket ve fiyatlarımızı incelemek için
                <router-link to="/hizmet-saglayici/kurumsal-hizmetler"
                  >tıklayınız</router-link
                >.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="seperator">
        <img src="@/assets/img/hero/bg_yay.svg" alt />
      </div>

      <div class="rods rellax">
        <img
          class="r01"
          src="@/assets/img/hero/rod_01.svg"
          alt="İleti Yönetim Sistemi"
          v-rellax="{ speed: -2.3 }"
        />
        <img
          class="r02"
          src="@/assets/img/hero/rod_02.svg"
          alt="İleti Yönetim Sistemi"
          v-rellax="{ speed: -4.5 }"
        />
        <img
          class="r03"
          src="@/assets/img/hero/rod_03.svg"
          alt="İleti Yönetim Sistemi"
          v-rellax="{ speed: -3 }"
        />
        <img
          class="r04"
          src="@/assets/img/hero/rod_04.svg"
          alt="İleti Yönetim Sistemi"
          v-rellax="{ speed: -3 }"
        />
        <img
          class="r05"
          src="@/assets/img/hero/rod_05.svg"
          alt="İleti Yönetim Sistemi"
          v-rellax="{ speed: 1.5 }"
        />
        <img
          class="r06"
          src="@/assets/img/hero/rod_06.svg"
          alt="İleti Yönetim Sistemi"
          v-rellax="{ speed: -4 }"
        />
        <img
          class="r07"
          src="@/assets/img/hero/rod_07.svg"
          alt="İleti Yönetim Sistemi"
          v-rellax="{ speed: -2 }"
        />
        <img
          class="r08"
          src="@/assets/img/hero/rod_08.svg"
          alt="İleti Yönetim Sistemi"
          v-rellax="{ speed: -1 }"
        />
      </div>
    </div>

    <!-- VİDEO
      <div class="video rellax" v-rellax="{ speed: -2, }" @click="SET_MODAL('ModalVideo')">
      <img class="art" src="@/assets/img/hero/video_thumb.png" alt="İleti Yönetim Sistemi" />
      <img class="playbtn" src="@/assets/img/hero/play.svg" alt="İleti Yönetim Sistemi" />
    </div>-->

    <div style="display: none">
      <div class="container">
        <div class="content">
          <div class="detailbox">
            <!-- <div class="infoline mt2 rellax" v-rellax="{ speed: -0.8}">
              <img src="@/assets/img/ico_info.svg" alt />
              <div
                class="desc"
              >Hizmet Sağlayıcılar için İleti Yönetim Sistemi hizmetleri ve fiyatları açıklanmıştır.</div>
              <div class="btn second">
                <router-link to="/hizmet-saglayici/hizmetler-ve-paketler">Paketleri incele</router-link>
              </div>
            </div>-->

            <div class="main mt2">
              <h3>
                <!-- <tool-tip-hs></tool-tip-hs> -->
                Hizmet Sağlayıcılar İçin
              </h3>
              <p>
                İleti Yönetim Sistemi ile müşterilerinizin ticari elektronik
                ileti izinlerini güvenle ve kolayca yönetebilirsiniz!
              </p>
            </div>

            <div class="linescontainer">
              <div class="linesgrid">
                <div class="lines">
                  <img src="@/assets/img/icons/hp-h1.svg" alt />
                  <div class="text">
                    <h4>İzin Yönetimi</h4>
                    <p>
                      İleti Yönetim Sistemi'ne kayıt olup mevcut izinlerinizi
                      bir defa ekledikten sonra iletişim onaylarına ilişkin tüm
                      süreci hızlı ve kolay bir şekilde yönetebilirsiniz.
                    </p>
                  </div>
                </div>

                <div class="lines">
                  <img src="@/assets/img/icons/hp-h2.svg" alt />
                  <div class="text">
                    <h4>Onay ve Ret Bildirimi</h4>
                    <p>
                      Müşterilerinizin onay ve ret bildirimlerini farklı
                      kanallardan kolayca toplayabilir, işletmenize ait
                      markalarınız için bu izinleri ayrı ayrı yönetebiliriniz.
                    </p>
                  </div>
                </div>

                <div class="lines">
                  <img src="@/assets/img/icons/hp-h4.svg" alt />
                  <div class="text">
                    <h4>İleti Yönetimi Hizmetleri</h4>
                    <p>
                      Tüm hizmet sağlayıcılar İYS’yi Temel Hizmetler kapsamında
                      mevzuatın zorunlu kıldığı tüm işlevler için ücretsiz
                      olarak kullanabilirler.
                    </p>
                  </div>
                </div>

                <div class="lines">
                  <img src="@/assets/img/icons/hp-h3.svg" alt />
                  <div class="text">
                    <h4>İzinlere Hukuki Dayanak</h4>
                    <p>
                      İleti Yönetim Sistemi'ne yüklenen veya İleti Yönetim
                      Sistemi aracılığıyla alınan tüm onay/ret bildirimleri
                      zaman damgasıyla işaretlenir. Böylece izinlerinizin
                      varlığı hukuki olarak geçerlilik kazanır.
                    </p>
                  </div>
                </div>
              </div>

              <div class="detay btn">
                <router-link to="hizmet-saglayici/kurumsal-hizmetler">
                  Detaylı bilgi
                  <img src="@/assets/img/ico_detail_r.svg" alt />
                </router-link>
              </div>

              <!-- <div class="lines">
                <img src="@/assets/img/icons/hp-h5.svg" alt />
                <div class="text">
                  <h4>Veri güvenliği</h4>
                  <p>
                   Ekleyeceğiniz ticari elektronik ileti izinleri özel kriptografi cihazları ile size özel anahtar kullanılarak şifrelenir.
                    <br />Böylece yüklediğiniz verilere sizin dışınızda hiç kimse erişemez.
                  </p>
                </div>
              </div>-->
            </div>

            <!-- <div class="infoline">
              <img src="@/assets/img/ico_info.svg" alt />
              <div class="desc">
                31 Aralık 2020 tarihine kadar mevcut izinlerin İleti Yönetim Sistemi
                üzerine aktarılması gerekmektedir. Bu tarihe kadar aktarılmayan
                izinler geçersiz sayılacaktır.
              </div> -->
            <!-- <div
                @click="
                  SET_MODAL('ModalPreFaz');
                  SET_NOTIFY_REQUESTER_TYPE(1);
                "
                class="btn second"
              >
                <a>Beni Haberdar Et</a>
              </div>-->
            <!--
              <div class="btn">
                <router-link to="/hizmet-saglayici/basvuru/nasil-yapilir">Hemen Başvur</router-link>
              </div>
            </div> -->
          </div>

          <div class="detailbox mt2">
            <div class="main">
              <h3>Vatandaşlar İçin</h3>
              <p>
                İşletmelere
                <b> (Hizmet sağlayıcılar) </b>
                vermiş olduğunuz ticari elektronik ileti izinlerinizi İleti
                Yönetim Sistemi üzerinde görüntüleyebilecek ve dilerseniz
                değişiklik yapabileceksiniz.
                <!-- <br />Bunun yanında şikâyetlerinizi İleti Yönetim Sistemi
                aracılığıyla kamuya iletebilir, önceki şikâyetlerinizin
                durumlarını görüntüleyebilir ve sonuçlarıyla ilgili bilgi
                alabilirsiniz.-->
              </p>
            </div>

            <div class="linescontainer">
              <div class="linesgrid">
                <div class="lines">
                  <img src="@/assets/img/icons/hp-v1.svg" alt />
                  <div class="text">
                    <h4>İzin Yönetimi</h4>
                    <p>
                      Hizmet sağlayıcılar ve markalarına vereceğiniz izinleri
                      her kanal için (<b>arama</b>,
                      <b>mesaj</b>, <b>e-posta</b>) ayrı ayrı
                      görüntüleyebilecek, üzerinde değişiklik yapabileceksiniz.
                    </p>
                  </div>
                </div>

                <div class="lines">
                  <img src="@/assets/img/icons/hp-v2.svg" alt />
                  <div class="text">
                    <h4>Onay ve Ret Bildirimi</h4>
                    <p>
                      İleti Yönetim Sistemi aracılığıyla hizmet sağlayıcılara
                      iletişim onayı verebilecek, dilerseniz reddetme hakkınızı
                      kullanabileceksiniz. Bu durumda yeniden onay verene dek
                      ilgili Hizmet Sağlayıcı'dan ticari elektronik ileti
                      almayacaksınız.
                    </p>
                  </div>
                </div>

                <!-- <div class="lines">
                  <img src="@/assets/img/icons/hp-v3.svg" alt />
                  <div class="text">
                    <h4>Şikâyet Sistemi</h4>
                    <p>
                      İYS ile birlikte, vatandaşların ticari elektronik ileti onaylarına ilişkin şikayetlerinin hızlı biçimde sonuca ulaştırılması mümkün olacak.
                    </p>
                  </div>
                </div> -->

                <div class="lines">
                  <img src="@/assets/img/icons/hp-v4.svg" alt />
                  <div class="text">
                    <h4>Erişim Kolaylığı</h4>
                    <p>
                      Çok yakında İleti Yönetim Sistemi'ne;
                      <b>İleti Yönetim Sistemi Web</b>,
                      <b>İleti Yönetim Sistemi Mobil</b> ve
                      <b>e-Devlet</b> üzerinden ulaşabileceksiniz.
                    </p>
                  </div>
                </div>
              </div>

              <div class="detay btn">
                <router-link to="/vatandas-hizmetleri">
                  Detaylı bilgi
                  <img src="@/assets/img/ico_detail_r.svg" alt />
                </router-link>
              </div>
            </div>

            <div class="infoline">
              <img src="@/assets/img/ico_info.svg" alt />
              <div class="desc">
                İleti Yönetim Sistemi giriş yaparak hemen ticari elektronik
                ileti izinlerinizi yönetebilirsiniz.
              </div>
              <div class="btn second">
                <a href="http://vatandas.iys.org.tr/">Vatandaş Girişi</a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="timecont">
        <div class="timeline">
          <div class="title">
            <h3>Takvim</h3>

            <ul
              class="controls"
              id="customize-controls"
              aria-label="Carousel Navigation"
              tabindex="0"
            >
              <li
                class="prev"
                aria-controls="customize"
                tabindex="-1"
                data-controls="prev"
              >
                <img src="@/assets/img/hero/prev.svg" alt />
              </li>

              <li
                class="next"
                aria-controls="customize"
                tabindex="-1"
                data-controls="next"
              >
                <img src="@/assets/img/hero/next.svg" alt />
              </li>
            </ul>
          </div>

          <tiny-slider v-bind="tinySliderOptions">
            <!-- Div Çoğaltıcı: v-for="index in 5" :key="index" -->

            <div class="capsule">
              <div class="boxp">
                <div class="box">
                  İleti Yönetim Sistemi'nin açılması, Hizmet Sağlayıcı ve
                  vatandaşların bilgilendirilmesi
                </div>
                <div class="image">
                  <img src="@/assets/img/hero/zc-01.svg" alt />
                </div>
                <div class="hook">
                  <div class="vert"></div>
                  <div class="circ"></div>
                  <div class="horz">
                    <div class="part noline"></div>
                    <div class="part"></div>
                  </div>
                </div>
                <div class="date">Ocak 2020</div>
                <!-- <div id="clear" style="clear:both;"></div> -->
              </div>
            </div>

            <div class="capsule">
              <div class="boxp">
                <div class="box">
                  Hizmet Sağlayıcılar için İleti Yönetim Sistemi başvurularının
                  açılması
                </div>
                <div class="image">
                  <img src="@/assets/img/hero/zc-02.svg" alt />
                </div>
                <div class="hook">
                  <div class="vert"></div>
                  <div class="circ"></div>
                  <div class="horz">
                    <div class="part"></div>
                    <div class="part"></div>
                  </div>
                </div>
                <div class="date">Ocak 2020</div>
                <!-- <div id="clear" style="clear:both;"></div> -->
              </div>
            </div>

            <div class="capsule">
              <div class="boxp">
                <div class="box">
                  Hizmet Sağlayıcılar için İleti Yönetim Sistemi platformunun
                  açılması
                </div>
                <div class="image">
                  <img src="@/assets/img/hero/zc-03.svg" alt />
                </div>
                <div class="hook">
                  <div class="vert"></div>
                  <div class="circ"></div>
                  <div class="horz">
                    <div class="part"></div>
                    <div class="part"></div>
                  </div>
                </div>
                <div class="date">Temmuz 2020</div>
                &nbsp;
                <!-- <div id="clear" style="clear:both;"></div> -->
              </div>
            </div>

            <div class="capsule">
              <div class="boxp">
                <div class="box">
                  Alıcılar (vatandaşlar) ve kamu için İleti Yönetim Sistemi'nin
                  açılması
                </div>
                <div class="image">
                  <img src="@/assets/img/hero/zc-04.svg" alt />
                </div>
                <div class="hook">
                  <div class="vert"></div>
                  <div class="circ"></div>
                  <div class="horz">
                    <div class="part"></div>
                    <div class="part"></div>
                  </div>
                </div>
                <div class="date">1 Ocak 2021</div>
                &nbsp;
                <!-- <div id="clear" style="clear:both;"></div> -->
              </div>
            </div>

            <div class="capsule zmax">
              <div class="boxp zmax">
                <div class="box">
                  İletişim adresi sayısı 150.000 adedin üzerinde olan hizmet
                  sağlayıcılar için geçmiş tarihli izinlerin onaylı olarak kabul
                  edilmesi
                  <tool-tip
                    >İletişim adresi sayısı 150.000 adet ve <br />
                    altında olan hizmet sağlayıcılar için bu tarih <br />
                    15 Temmuz 2021'dir.
                  </tool-tip>
                  <div class="image">
                    <img src="@/assets/img/hero/zc-05.svg" alt />
                  </div>
                </div>

                <div class="hook">
                  <div class="vert"></div>
                  <div class="circ"></div>
                  <div class="horz">
                    <div class="part"></div>
                    <div class="part"></div>
                  </div>
                </div>
                <div class="date">15 Şubat 2021</div>
                <!-- <div id="clear" style="clear:both;"></div> -->
              </div>
            </div>

            <div class="capsule zmax">
              <div class="boxp zmax">
                <div class="box">
                  İletişim adresi sayısı 150.000 adedin üzerinde olan hizmet
                  sağlayıcılar için ileti gönderiminde aracı hizmet sağlayıcı
                  sorgusunun başlaması
                  <tool-tip
                    >AHS tarafından İYS’de onaylı olmayan telefon numaralarına
                    <br />
                    ticari elektronik ileti gönderiminin durdurulması
                  </tool-tip>
                  <div class="image">
                    <img src="@/assets/img/hero/zc-06.svg" alt />
                  </div>
                </div>

                <div class="hook">
                  <div class="vert"></div>
                  <div class="circ"></div>
                  <div class="horz">
                    <div class="part"></div>
                    <div class="part"></div>
                  </div>
                </div>
                <div class="date">16 Şubat 2021</div>
                <!-- <div id="clear" style="clear:both;"></div> -->
              </div>
            </div>

            <div class="capsule zmax">
              <div class="boxp zmax">
                <div class="box">
                  İletişim adresi sayısı 150.000 adedin altında olan hizmet
                  sağlayıcılar için geçmiş tarihli izinlerin vatandaşlar
                  tarafından kontrolünün başlaması
                  <div class="image">
                    <img src="@/assets/img/hero/zc-09.svg" alt />
                  </div>
                </div>

                <div class="hook">
                  <div class="vert"></div>
                  <div class="circ"></div>
                  <div class="horz">
                    <div class="part"></div>
                    <div class="part"></div>
                  </div>
                </div>
                <div class="date">1 Haziran 2021</div>
                <!-- <div id="clear" style="clear:both;"></div> -->
              </div>
            </div>

            <div class="capsule zmax">
              <div class="boxp zmax">
                <div class="box">
                  İletişim adresi sayısı 150.000 adedin altında olan hizmet
                  sağlayıcılar için geçmiş tarihli izinlerin onaylı olarak kabul
                  edilmesi
                  <div class="image">
                    <img src="@/assets/img/hero/zc-08.svg" alt />
                  </div>
                </div>

                <div class="hook">
                  <div class="vert"></div>
                  <div class="circ"></div>
                  <div class="horz">
                    <div class="part"></div>
                    <div class="part"></div>
                  </div>
                </div>
                <div class="date">15 Temmuz 2021</div>
                <!-- <div id="clear" style="clear:both;"></div> -->
              </div>
            </div>

            <div class="capsule zmax">
              <div class="boxp zmax">
                <div class="box">
                  İletişim adresi sayısı 150.000 adedin altında olan hizmet
                  sağlayıcılar için ileti gönderiminde aracı hizmet sağlayıcı
                  sorgusunun başlaması
                  <tool-tip
                    >AHS tarafından İYS’de onaylı olmayan telefon numaralarına
                    <br />
                    ticari elektronik ileti gönderiminin durdurulması
                  </tool-tip>
                  <div class="image">
                    <img src="@/assets/img/hero/zc-06.svg" alt />
                  </div>
                </div>

                <div class="hook">
                  <div class="vert"></div>
                  <div class="circ"></div>
                  <div class="horz">
                    <div class="part"></div>
                    <div class="part noline"></div>
                  </div>
                </div>
                <div class="date">16 Temmuz 2021</div>
                <div id="clear" style="clear: both"></div>
              </div>
            </div>
          </tiny-slider>
        </div>
        <div class="line"></div>
      </div>

      <div class="sss rellax">
        <div class="ssscontent">
          <div class="header">
            <h3 class="blue">Sıkça Sorulan Sorular</h3>
          </div>
          <div class="questions">
            <div>
              <h4>İleti Yönetim Sistemi nedir?</h4>
              <p>
                İleti Yönetim Sistemi, Hizmet Sağlayıcılar'ın
                <b>arama</b>, <b>mesaj</b> ve <b>e-posta</b> yoluyla
                <b>ticari elektronik ileti</b> göndermek için alıcılardan
                aldıkları onayları saklayıp yönetebilmesine, alıcıların ise
                <b>onay</b> ve <b>ret</b> hakkını kullanabilmesine, ticari
                elektronik ileti şikâyetlerini iletebilmesine ve
                yönetilebilmesine imkân tanıyan, güvenlik standartlarına sahip
                ulusal güvenli veri tabanı sistemidir.
              </p>

              <h4>Hizmet Sağlayıcı kimdir?</h4>
              <p>
                Telefon, çağrı merkezi, otomatik arama makinesi, akıllı ses
                kaydedici sistem, elektronik posta, kısa mesaj hizmeti gibi
                vasıtalar kullanılarak elektronik ortamda gerçekleştirilen ve
                ticari amaçlarla veri, ses ve görüntü içerikli ileti gönderimi
                yapan gerçek veya tüzel kişilere
                <b>Hizmet Sağlayıcı</b> denir.
              </p>
            </div>
            <div>
              <h4>Ticari elektronik ileti nedir?</h4>
              <p>
                Hizmet Sağlayıcılar'ın, mal ve hizmetlerini pazarlamak,
                işletmesini tanıtmak ya da kutlama ve temenni gibi içeriklerle
                tanınırlığını artırmak amacıyla alıcıların elektronik iletişim
                adreslerine promosyon, kampanya, indirim, hediye içerikli
                gönderdiği iletiler ticari elektronik iletidir.
              </p>
              <h4>Alıcı kimdir?</h4>
              <p>
                İşletmeler yani hizmet Sağlayıcılar tarafından gönderilen ticari
                elektronik iletilerin muhatabı olan arama, mesaj ve e-posta gibi
                iletişim adresi sahibi kişiler alıcı olarak anılmaktadır.
              </p>
            </div>
          </div>
          <div class="btn">
            <router-link to="/iys/sss">Tüm Cevapları İncele</router-link>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="footerimg">
      <img src="@/assets/img/character.svg" alt />
    </div>-->
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import Swal from "sweetalert2";

export default {
  name: "Homepage",

  methods: {
    ...mapMutations("app", ["SET_MODAL", "SET_NOTIFY_REQUESTER_TYPE"]),

    TANITIM_FILMI_CLICKED() {
      this.$router.push("/tanitim");

      // Swal.fire({
      //   type: "",
      //   customClass: {
      //     container: "popup_container"
      //   },
      //   width: "95%",
      //   html: `<div id="player" style="padding: 2vh !important; margin:0 !important; background: #fff; border:0">
      //           <iframe width="95%" height="720" src="https://www.youtube.com/embed/bTqVqk7FSmY" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      //         </div>`,
      //   showCloseButton: true,
      //   showConfirmButton: false

      // });
    },
  },
  mounted() {},

  data() {
    return {
      tinySliderOptions: {
        responsive: { 500: { items: 1 } },
        responsive: { 700: { items: 2 } },

        mouseDrag: true,
        center: false,
        loop: false,
        swipeAngle: 450,
        speed: 500,
        rewind: true,
        nav: false,
        startIndex: 3,
        controlsContainer: "#customize-controls",
      },
    };
  },
};
</script>

<style scoped>
/* .popup_container {
    padding: 0 !important;
    margin: 0 !important;
  }

  a.bilgi {
    background: #4da0df;
    color: #fff;
    float: right;
    padding: 0.5vh;
    border-radius: 0.5vh;
  }

  a.bilgi:hover {
    transform: scale(1.1);
  } */
</style>
